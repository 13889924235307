@keyframes loading-animation {
  0% {
    color: rgba(0, 0, 0, 0.1);
  }
  50% {
    color: rgba(0, 0, 0, 0.5);
  }
  100% {
    color: rgba(0, 0, 0, 0.1);
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgba(73, 73, 73, 0.5);
  border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(73, 73, 73, 0.8);
  border-radius: 30px;
}

*,
:after,
:before {
  box-sizing: border-box;
}

body {
  font-size: initial;
  font-family: "Lato", sans-serif;
  line-height: 1.42857143;
  margin: 0;
  background-color: #fff;
  color: #333;
}

html {
  font-size: initial;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 10px;
}

h1 {
  margin-top: 8px !important;
  font-size: 18pt !important;
  width: 90%;
  color: #053d5e;
}

h2,
h3 {
  color: #053d5e;
}

small {
  font-size: 85%;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

p {
  margin: 0 0 10px;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: Menlo, Monaco, Consolas, Courier New, monospace;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:focus,
a:hover {
  color: #23527c;
  text-decoration: underline;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

button,
input,
optgroup,
select,
textarea {
  /* margin: 0; */
  font: inherit;
  color: inherit;
}

.wrap-long-text {
  word-wrap: break-word;
}

@media screen and (max-width: 1919px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 25px;
  }

  h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 1350px) {
  h1 {
    font-size: 25px;
  }

  h2 {
    font-size: 20px;
  }

  h3,
  h4 {
    font-size: 15px;
  }
}
